<script>
/**
 * Doughnut component extending Charts js
 */
import { Doughnut, mixins } from "vue-chartjs";
const { reactiveProp } = mixins

export default {
  extends: Doughnut,
  mixins: [reactiveProp],
  props: ["options"],
  mounted() {
    this.addPlugin({
      id: 'zero-template-plugin',
      beforeUpdate: function(chart) {
        if(chart.data.datasets.length > 0) {
          var labels = chart.data.labels
          var data = chart.data.datasets[0].data;
          var isAllZero = data && data.reduce((a, b) => a + b) > 0 ? false : true;
          if (labels && !isAllZero) {
            chart.options.tooltips.displayColors = true;
            chart.options.tooltips.enabled = true;
            chart.options.legend.display = true;
            return;
          } else {
            // when all data values are zero...
            // chart.data.datasets[0].data = data.map((e, i) => i > 0 ? 0 : 1); //add one segment
            // chart.data.datasets[0].backgroundColor = chart.data.datasets[0].backgroundColor.map(() => '#d2dee2');
            chart.data.datasets[0].data = [1];
            chart.data.datasets[0].backgroundColor = ['#d2dee2'];
            chart.data.labels = ["NO DATA"];
            chart.data.datasets[0].count_data = [1];
            chart.data.datasets[0].borderWidth = 0; //no border
            chart.options.tooltips.enabled = true; //disable tooltips
            chart.options.tooltips.displayColors = false; //disable tooltips colors
            chart.options.legend.display = false; //disable legend click
          }
        }
      }
    });
    this.renderChart(this.chartdata, this.options);
  },
};
</script>
