<script>
import Doughnut from "./chart-js/Doughnut";
import * as cloneDeep from "lodash.clonedeep";
// import * as moment from "moment";

export default {
  components: {
    Doughnut,
  },
  props: ['filter'],
  data: () => ({
    height:"230",
    chartData: {
      labels: [],
      datasets: [],
    },
    options: {
      tooltips: {
        enabled: true,
        callbacks: {
          label: function(tooltipItem, data) {
            if(data && data.labels.includes("NO DATA")) {
              return "NO DATA";
            }
            let sum = 0;
            let dataArr = data.datasets[0].data;
            dataArr.map(data => {
                sum += data;
            });
            let percentage = ((data['datasets'][0]['data'][tooltipItem['index']])*100 / sum).toFixed(2)
            return `${data['labels'][tooltipItem['index']]}: ${percentage}%, `
             + `Amount: ${(data['datasets'][0]['data'][tooltipItem['index']]).toLocaleString('en-US', {style : 'currency', currency : 'USD'})}, `
             + `Count: ${data['datasets'][0]['count_data'][tooltipItem['index']]}`;
          }
        }
      },
      cutoutPercentage: 70,
      legend: {
        display: true,
        position: 'bottom',
      },
      responsive: true,
      maintainAspectRatio: false,
    },
  }),
  computed: {
    statData() {
      return this.$store.state.remittancesDashboard.txn_stats;
    },
    chartDataStore() {
      return this.$store.state.remittancesDashboard.txn_vol_by_pmt_method;
    },
  },
  watch: {
    chartDataStore() {
      this.chartData = cloneDeep(this.chartDataStore);
      //TODO: find better way to replace ALL with UNKNOWN
      // if(this.chartData) {
      //   const index = this.chartData.labels.indexOf('ALL')
      //   if(index != -1) this.chartData.labels[index] = 'UNKNOWN'
      // }
      let datacount = this.chartData.datasets[0].data.filter(item => item > 0);
      if(this.chartData.labels.length > 20 && datacount.length > 0){
        this.height = "450"
      }else if(this.chartData.labels.length > 10 && datacount.length > 0){
        this.height = "330"
      }else{
        this.height = "230"
      }
    }
  },
  mounted() {
    this.chartData = cloneDeep(this.chartDataStore);
  },
  methods: {
    // getFormattedPeriod() {
    //   if(this.statData && this.statData.date_range) {
    //     const startDate = this.statData.date_range.start;
    //     const endDate = this.statData.date_range.end;
    //     if(this.filter == 'hours') {
    //       return `${moment(startDate).format('YYYY/MM/DD HH:mm')} - ${moment(endDate).format('HH:mm')}`;
    //     } else if(this.filter == 'days') {
    //       return `${moment(startDate).format('YYYY/MM/DD HH:mm')} - ${moment(endDate).format('HH:mm')}`;
    //     } else if(this.filter == 'weeks') {
    //       return `${moment(startDate).format('YYYY/MM/DD')} - ${moment(endDate).format('YYYY/MM/DD')}, Week: ${moment(startDate).week()}`;
    //     } else if(this.filter == 'months') {
    //       return `${moment(startDate).format('YYYY/MM')}`;
    //     } else if(this.filter == 'years') {
    //       return `${moment(startDate).format('YYYY')}`;
    //     } else {
    //       return `${moment(startDate).format('YYYY/MM/DD HH:mm')} - ${moment(endDate).format('YYYY/MM/DD HH:mm')}`;
    //     }
    //   }
    // }
  },
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <div>
        <h4 class="card-title mb-4">
          <span id="trxn_vol_by_pm" >{{ $t('views.dashboard.trxn_vol_by_pm') }}</span>
          <div style="width: 35px;border: 2px solid #354052;" class="mt-2"></div>
        </h4>
        <!-- <b-tooltip target="trxn_vol_by_pm" triggers="hover">
          <span v-if="filter === 'hours'" class="">{{ `Calculated hourly: ${getFormattedPeriod()}` }}</span>
          <span v-else-if="filter === 'days'" class="">{{ `Calculated daily: ${getFormattedPeriod()}` }}</span>
          <span v-else-if="filter === 'weeks'" class="">{{ `Calculated weekly: ${getFormattedPeriod()}` }}</span>
          <span v-else-if="filter === 'months'" class="">{{ `Calculated monthly: ${getFormattedPeriod()}` }}</span>
          <span v-else-if="filter === 'years'" class="">{{ `Calculated yearly: ${getFormattedPeriod()}` }}</span>
        </b-tooltip> -->
      </div>
      <div id="donut-chart" class="chartjs-charts"></div>
      <Doughnut :chart-data="chartData"  :options="options"
      :style="{
            'height': `${height}px`,
            'margin':'25px'
      }" />
    </div>
  </div>
</template>
