<script>
import StackedBar from "./chart-js/StackedBar";
import * as cloneDeep from "lodash.clonedeep";
import * as moment from "moment";
let currentFilter = 'days';
// let currency = "";
/**
 * Transaction volume Stacked Bar component
 */
export default {
  props: ['filter','currentSelectedFilter'],
  components: {
    StackedBar,
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [],
      },
      options: {
        legend: {
          display: true,
          position: 'bottom',
        },
        datasets: {
          bar: {
            categoryPercentage: 0.5,
            barPercentage: 1,
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            gridLines: {
              display: true,
              drawOnChartArea: false,
            },
            stacked: true,
            ticks: {
              autoSkip: true,
              maxTicksLimit: 8,
              maxRotation: 0,
              minRotation: 0,
              // stepSize: 4
              callback: function(dataLabel, index) {
                const monthAbbr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
                const date = new Date(dataLabel);
                if(currentFilter === 'hours') {
                  return index === 0 ? moment(date).format('YYYY/MM/DD HH:mm') : moment(date).format('HH:mm');
                } else if(currentFilter === 'days') {
                  // return index === 0 ? moment(date).format('YYYY/MM/DD') : moment(date).format('MM/DD');
                  return index === 0 ? moment(date).format('YYYY/MM/DD') : `${moment(date).date()} ${monthAbbr[moment(date).month()]}`;
                } else if(currentFilter === 'weeks') {
                  return index === 0 ? moment(date).format('YYYY/MM/DD') + ` Week-${moment(date).week()}` : `${moment(date).date()} ${monthAbbr[moment(date).month()]}`;
                } else if(currentFilter === 'months') {
                  // return moment(date).format('YYYY/MM');
                  return `${monthAbbr[moment(date).month()]} ${moment(date).year()}`;
                } else if(currentFilter === 'years') {
                  return moment(date).format('YYYY');
                } else {
                  return dataLabel;
                }
              }
            }
          }],
          yAxes: [{
            stacked: true,
            ticks: {
              // autoSkip: true,
              // maxTicksLimit: 6,
              // stepSize: 4
              callback: function(dataLabel) {
                // TODO: Remove hardcoding for currency and find a way to fetch it here
                return (dataLabel).toLocaleString('en-US', {style : 'currency', currency : 'USD'});
              }
            }
          }]
        },
        tooltips: {
          enabled: true,
          callbacks: {
            title: function(tooltipItem) {
              const date = new Date(tooltipItem[0].xLabel);
              if(currentFilter == 'hours') {
                return moment(date).format('YYYY/MM/DD HH:mm');
              } else if (currentFilter == 'days') {
                return moment(date).format('YYYY/MM/DD');
              } else if (currentFilter == 'weeks') {
                return moment(date).format('YYYY/MM/DD') + ` Week-${moment(date).week()}`;
              } else if (currentFilter == 'months') {
                return moment(date).format('YYYY/MM');
              } else if (currentFilter == 'years') {
                return moment(date).format('YYYY');
              } else {
                return tooltipItem[0].xLabel;
              }
            },
            label: function(tooltipItem, data) {
              let sum = 0;
              data.datasets.map((ele) => {
                sum += ele.data[tooltipItem.index];
              });
              let percentage = 0;
              if(sum != 0) {
                percentage = (parseInt(tooltipItem.value) / sum * 100).toFixed(2)
              }
              // return ' ' + data.datasets[tooltipItem.datasetIndex].label + ': ' + (tooltipItem.yLabel).toLocaleString('en-US', {style : 'currency', currency : 'VND'});
              return `${data.datasets[tooltipItem.datasetIndex].label}: ${percentage}%, Amount: ${(tooltipItem.yLabel).toLocaleString('en-US', {style : 'currency', currency : 'USD'})}`;
            }
          }
        }
      }
    };
  },
  created() {
    // currency = this.convertCountryToCurrency
  },
  computed: {
    chartDataStore() {
      return this.$store.state.remittancesDashboard.txn_vol_stacked_bar;
    },
    convertCountryToCurrency() {
      return this.$store.getters["auth/getConvertCountry"];
    },
  },
  watch: {
    chartDataStore() {
      this.chartData = cloneDeep(this.chartDataStore);
    },
    filter() {
      currentFilter = this.filter;
    },
    currentSelectedFilter() {
      // currency = this.currentSelectedFilter;
    }
  },
  mounted() {
    this.chartData = cloneDeep(this.chartDataStore);
  },
}
</script>

<template>
  <div class="card">
    <div class="card-body">
      <div>
        <h4 class="card-title mb-4">
          <span id="trxn_vol_stacked_bar">Transaction Amount by Currency </span>
          <!-- <i class="mdi mdi-information-outline" :title="$t('views.dashboard.tooltip_trxn_vol')" v-b-tooltip.hover></i> -->
          <div style="width: 35px;border: 2px solid #354052;" class="mt-2"></div>
        </h4>
        <!-- <b-tooltip target="trxn_vol_stacked_bar" triggers="hover">
          <span class="">{{ $t('views.dashboard.tooltip_trxn_vol') }}</span>
        </b-tooltip> -->
      </div>
      <StackedBar :chart-data="chartData" :options="options"       :style="{
            'height': '290px'
      }"  />
    </div>
  </div>
</template>
