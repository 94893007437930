<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import * as moment from "moment";
import Stat from "../dashboard/widget";
// import TransactionVolume from "../dashboard/transaction_volume";
import TransactionByPG from "../dashboard/donut-transaction-by-pg";
import TransactionByStatus from "../dashboard/donut-transaction-by-status";
import TransactionByType from "../dashboard/donut-transaction-by-type";
// import TransactionSeasonality from "../dashboard/transaction_seasonality";
import TransactionVolumeStackedbar from "../dashboard/transaction_volume_stackedbar.vue";
import TransactionVolumeStackedbarByChannel from "../dashboard/transaction_volume_stackedbar_by_channel.vue";
import TransactionVolumeStackedbarByMethod from "../dashboard/transaction_volume_stackedbar_by_method.vue";
// import Multiselect from 'vue-multiselect';
import FilterBtn from '../../../../components/filter-btn.vue';
import {
  FETCH_REMITTANCES_TRANSACTION_STATS,
  // FETCH_TRANSACTION_VOLUME,
  // FETCH_TRANSACTION_VOLUME_SEASONALITY,
  FETCH_REMITTANCES_TRANSACTION_DATA_FOR_DOUGHNUT_CHARTS,
  FETCH_REMITTANCES_TRANSACTION_VOLUME_STACKED_BAR,
  FETCH_MERCHANTS_LIST
} from '../../../../state/actions.type';

/**
 * Dashboard component
 */
export default { 
  components: {
    Layout,
    PageHeader,
    Stat,
    // TransactionVolume,
    TransactionByPG,
    TransactionByStatus,
    TransactionByType,
    // TransactionSeasonality,
    TransactionVolumeStackedbar,
    TransactionVolumeStackedbarByChannel,
    TransactionVolumeStackedbarByMethod,
    // Multiselect,
    FilterBtn,
    DateRangePicker
  },

  created() {
    this.currentSelectedFilter = this.convertCountryToCurrency
    var from_date = moment(new Date(this.pickerDates.startDate.getFullYear(), this.pickerDates.startDate.getMonth(), this.pickerDates.startDate.getDate(), 0, 0, 0 )).format("YYYY-MM-DD HH:mm:ss");
    var to_date = moment(new Date(this.pickerDates.endDate.getFullYear(), this.pickerDates.endDate.getMonth(), this.pickerDates.endDate.getDate(), 23, 59, 59 )).format("YYYY-MM-DD HH:mm:ss");
    this.$store.dispatch(`remittancesDashboard/${FETCH_REMITTANCES_TRANSACTION_STATS}`, { filter: {}, from_date, to_date, isMasterMerchant:this.isMasterMerchant, merchant_uuids:this.merchant_uuids, currency:this.currentSelectedFilter});
    // this.$store.dispatch(`dashboard/${FETCH_TRANSACTION_VOLUME}`, { filter: 'days', isMasterMerchant:this.isMasterMerchant, merchant_uuids:this.merchant_uuids, currency:this.currentSelectedFilter});
    // this.$store.dispatch(`dashboard/${FETCH_TRANSACTION_VOLUME_SEASONALITY}`, { filter: 'days', isMasterMerchant:this.isMasterMerchant, merchant_uuids:this.merchant_uuids, currency:this.currentSelectedFilter});
    this.$store.dispatch(`remittancesDashboard/${FETCH_REMITTANCES_TRANSACTION_DATA_FOR_DOUGHNUT_CHARTS}`, { filter: {}, from_date, to_date, isMasterMerchant:this.isMasterMerchant, merchant_uuids:this.merchant_uuids, currency:this.currentSelectedFilter});
    this.$store.dispatch(`remittancesDashboard/${FETCH_REMITTANCES_TRANSACTION_VOLUME_STACKED_BAR}`, { filter: {}, from_date, to_date, isMasterMerchant:this.isMasterMerchant, merchant_uuids:this.merchant_uuids, currency:this.currentSelectedFilter});
    if(this.isMasterMerchant){
      this.$store.dispatch(`masterMerchant/${FETCH_MERCHANTS_LIST}`, {
        pageIndex: 1,
        pagesize: 10,
        filters: { source: "default" }
      });
    }
  },

  data() {
    const startDate = new Date();
    const endDate = new Date();
    startDate.setDate(startDate.getDate() - 6000);
    return {
      pickerDates: {
        startDate,
        endDate
      },
      appliedFilter: 'days',
      merchant_uuids:[],
      filterOptions: ['VND', 'THB', 'SGD', 'IDR', 'PHP'],
      currentSelectedFilter: "",
      isFilterApplied: false,
      showFilterSection:false,
      filter:{
        currency:[],
        method:[],
        channel:[]
      }
    }
  },
  filters: {
    date(date) {
      return moment(date).format("DD/MM/YYYY");
    }
  },

  computed: {
    title() {
      return this.$t('views.dashboard.dashboard')
    },

    items() {
      return  [
        {
          text: "REMITTANCES",
          active: true
        },
        {
          text: this.$t('views.dashboard.dashboard'),
          active: true
        }
      ]
    },

    merchantListDataFromStore() {
      return this.$store.state.masterMerchant.content;
    },

    isMasterMerchant() {
      return this.$store.state.auth.currentUser.is_master_merchant;
    },

    userPermissions() {
      return this.$store.getters["auth/getUserPermissionsList"];
    },

    convertCountryToCurrency() {
      return this.$store.getters["auth/getConvertCountry"];
    },
  },
  watch: {
    // merchant_uuids() {
    //   var filter = this.appliedFilter;
    //   this.$store.dispatch(`remittancesDashboard/${FETCH_REMITTANCES_TRANSACTION_STATS}`, { filter: filter, isMasterMerchant:this.isMasterMerchant, merchant_uuids:this.merchant_uuids, currency:this.currentSelectedFilter});
    //   this.$store.dispatch(`dashboard/${FETCH_TRANSACTION_VOLUME}`, { filter: filter, isMasterMerchant:this.isMasterMerchant, merchant_uuids:this.merchant_uuids, currency:this.currentSelectedFilter});
    //   this.$store.dispatch(`dashboard/${FETCH_TRANSACTION_VOLUME_SEASONALITY}`, { filter: filter, isMasterMerchant:this.isMasterMerchant, merchant_uuids:this.merchant_uuids, currency:this.currentSelectedFilter});
    //   this.$store.dispatch(`dashboard/${FETCH_TRANSACTION_VOLUME_STACKED_BAR}`, { filter: filter, isMasterMerchant:this.isMasterMerchant, merchant_uuids:this.merchant_uuids, currency:this.currentSelectedFilter});
    //   this.$store.dispatch(`remittancesDashboard/${FETCH_REMITTANCES_TRANSACTION_DATA_FOR_DOUGHNUT_CHARTS}`, { filter: filter, isMasterMerchant:this.isMasterMerchant, merchant_uuids:this.merchant_uuids, currency:this.currentSelectedFilter});
    // },
    pickerDates: {
        handler(){
          var from_date = moment(new Date(this.pickerDates.startDate.getFullYear(), this.pickerDates.startDate.getMonth(), this.pickerDates.startDate.getDate(), 0, 0, 0 )).format("YYYY-MM-DD HH:mm:ss");
          var to_date = moment(new Date(this.pickerDates.endDate.getFullYear(), this.pickerDates.endDate.getMonth(), this.pickerDates.endDate.getDate(), 23, 59, 59 )).format("YYYY-MM-DD HH:mm:ss");
          this.$store.dispatch(`remittancesDashboard/${FETCH_REMITTANCES_TRANSACTION_STATS}`, { filter: this.filter, from_date, to_date, isMasterMerchant:this.isMasterMerchant, merchant_uuids:this.merchant_uuids, currency:this.currentSelectedFilter});
          this.$store.dispatch(`remittancesDashboard/${FETCH_REMITTANCES_TRANSACTION_DATA_FOR_DOUGHNUT_CHARTS}`, { filter: this.filter, from_date, to_date, isMasterMerchant:this.isMasterMerchant, merchant_uuids:this.merchant_uuids, currency:this.currentSelectedFilter});
          this.$store.dispatch(`remittancesDashboard/${FETCH_REMITTANCES_TRANSACTION_VOLUME_STACKED_BAR}`, { filter: this.filter, from_date, to_date, isMasterMerchant:this.isMasterMerchant, merchant_uuids:this.merchant_uuids, currency:this.currentSelectedFilter});
        }, 
        deep: true
    },
    // currentSelectedFilter() {
    //   var from_date = moment(new Date(this.pickerDates.startDate.getFullYear(), this.pickerDates.startDate.getMonth(), this.pickerDates.startDate.getDate(), 0, 0, 0 )).format("YYYY-MM-DD HH:mm:ss");
    //   var to_date = moment(new Date(this.pickerDates.endDate.getFullYear(), this.pickerDates.endDate.getMonth(), this.pickerDates.endDate.getDate(), 23, 59, 59 )).format("YYYY-MM-DD HH:mm:ss");
    //   // var filter = this.appliedFilter;
    //   this.$store.dispatch(`remittancesDashboard/${FETCH_REMITTANCES_TRANSACTION_STATS}`, { filter: {}, from_date, to_date, isMasterMerchant:this.isMasterMerchant, merchant_uuids:this.merchant_uuids , currency:this.currentSelectedFilter});
    //   // this.$store.dispatch(`dashboard/${FETCH_TRANSACTION_VOLUME}`, { filter: filter, isMasterMerchant:this.isMasterMerchant, merchant_uuids:this.merchant_uuids , currency:this.currentSelectedFilter});
    //   // this.$store.dispatch(`dashboard/${FETCH_TRANSACTION_VOLUME_SEASONALITY}`, { filter: filter, isMasterMerchant:this.isMasterMerchant, merchant_uuids:this.merchant_uuids, currency:this.currentSelectedFilter});
    //   this.$store.dispatch(`remittancesDashboard/${FETCH_REMITTANCES_TRANSACTION_VOLUME_STACKED_BAR}`, { filter: {}, from_date, to_date, isMasterMerchant:this.isMasterMerchant, merchant_uuids:this.merchant_uuids, currency:this.currentSelectedFilter});
    //   this.$store.dispatch(`remittancesDashboard/${FETCH_REMITTANCES_TRANSACTION_DATA_FOR_DOUGHNUT_CHARTS}`, { filter: {}, from_date, to_date, isMasterMerchant:this.isMasterMerchant, merchant_uuids:this.merchant_uuids, currency:this.currentSelectedFilter});
    // }
  },

  methods: {
    // updateFilter(filter) {
    //   this.appliedFilter = filter;
    //   this.$store.dispatch(`remittancesDashboard/${FETCH_REMITTANCES_TRANSACTION_STATS}`, { filter: filter, isMasterMerchant:this.isMasterMerchant, merchant_uuids:this.merchant_uuids, currency:this.currentSelectedFilter});
    //   this.$store.dispatch(`dashboard/${FETCH_TRANSACTION_VOLUME}`, { filter: filter, isMasterMerchant:this.isMasterMerchant, merchant_uuids:this.merchant_uuids, currency:this.currentSelectedFilter});
    //   this.$store.dispatch(`dashboard/${FETCH_TRANSACTION_VOLUME_SEASONALITY}`, { filter: filter, isMasterMerchant:this.isMasterMerchant, merchant_uuids:this.merchant_uuids, currency:this.currentSelectedFilter});
    //   this.$store.dispatch(`dashboard/${FETCH_TRANSACTION_VOLUME_STACKED_BAR}`, { filter: filter, isMasterMerchant:this.isMasterMerchant, merchant_uuids:this.merchant_uuids, currency:this.currentSelectedFilter});
    //   this.$store.dispatch(`remittancesDashboard/${FETCH_REMITTANCES_TRANSACTION_DATA_FOR_DOUGHNUT_CHARTS}`, { filter: filter, isMasterMerchant:this.isMasterMerchant, merchant_uuids:this.merchant_uuids, currency:this.currentSelectedFilter});
    // }
    openFilter(){
      this.showFilterSection = !this.showFilterSection
    },
    saveFilter(){
      var from_date = moment(new Date(this.pickerDates.startDate.getFullYear(), this.pickerDates.startDate.getMonth(), this.pickerDates.startDate.getDate(), 0, 0, 0 )).format("YYYY-MM-DD HH:mm:ss");
      var to_date = moment(new Date(this.pickerDates.endDate.getFullYear(), this.pickerDates.endDate.getMonth(), this.pickerDates.endDate.getDate(), 23, 59, 59 )).format("YYYY-MM-DD HH:mm:ss");
      this.$store.dispatch(`remittancesDashboard/${FETCH_REMITTANCES_TRANSACTION_STATS}`, { filter: this.filter, from_date, to_date, isMasterMerchant:this.isMasterMerchant, merchant_uuids:this.merchant_uuids, currency:this.currentSelectedFilter});
      this.$store.dispatch(`remittancesDashboard/${FETCH_REMITTANCES_TRANSACTION_DATA_FOR_DOUGHNUT_CHARTS}`, { filter: this.filter, from_date, to_date, isMasterMerchant:this.isMasterMerchant, merchant_uuids:this.merchant_uuids, currency:this.currentSelectedFilter});
      this.$store.dispatch(`remittancesDashboard/${FETCH_REMITTANCES_TRANSACTION_VOLUME_STACKED_BAR}`, { filter: this.filter, from_date, to_date, isMasterMerchant:this.isMasterMerchant, merchant_uuids:this.merchant_uuids, currency:this.currentSelectedFilter});
      this.showFilterSection = false;
      if(this.filter.method.length > 0 || this.filter.channel.length > 0 || this.filter.currency.length > 0) {
        this.isFilterApplied = true;
      } else {
        this.isFilterApplied = false;
      }
    },
    clearFilter(){
      this.filter = {
        currency:[],
        method:[],
        channel:[]
      }
      var from_date = moment(new Date(this.pickerDates.startDate.getFullYear(), this.pickerDates.startDate.getMonth(), this.pickerDates.startDate.getDate(), 0, 0, 0 )).format("YYYY-MM-DD HH:mm:ss");
      var to_date = moment(new Date(this.pickerDates.endDate.getFullYear(), this.pickerDates.endDate.getMonth(), this.pickerDates.endDate.getDate(), 23, 59, 59 )).format("YYYY-MM-DD HH:mm:ss");
      this.$store.dispatch(`remittancesDashboard/${FETCH_REMITTANCES_TRANSACTION_STATS}`, { filter: {}, from_date, to_date, isMasterMerchant:this.isMasterMerchant, merchant_uuids:this.merchant_uuids, currency:this.currentSelectedFilter});
      this.$store.dispatch(`remittancesDashboard/${FETCH_REMITTANCES_TRANSACTION_DATA_FOR_DOUGHNUT_CHARTS}`, { filter: {}, from_date, to_date, isMasterMerchant:this.isMasterMerchant, merchant_uuids:this.merchant_uuids, currency:this.currentSelectedFilter});
      this.$store.dispatch(`remittancesDashboard/${FETCH_REMITTANCES_TRANSACTION_VOLUME_STACKED_BAR}`, { filter: {}, from_date, to_date, isMasterMerchant:this.isMasterMerchant, merchant_uuids:this.merchant_uuids, currency:this.currentSelectedFilter});
      this.showFilterSection = false;
      this.isFilterApplied = false;
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row"> 
      <div class="col-xl-12">
        <div class="position-relative mb-2">
            <div>
                <!-- <div v-if="isMasterMerchant" class="d-md-inline-block float-left mr-3" style="position: relative;bottom: 5px;width: 300px;">
                  <multiselect
                    v-model="merchant_uuids"
                    :options="merchantListDataFromStore"
                    placeholder="Search Merchants"
                    track-by="email_id"
                    label="email_id"
                    size="sm"
                    :searchable="true"
                    :multiple="true"
                    :taggable="true"
                  ></multiselect>
              </div> -->
              <date-range-picker class="datepicker-custom" style="margin-right: 15px;" opens="right" :autoApply="true" :showDropdowns="true" :ranges="false" v-model="pickerDates">
                <template
                  v-slot:input=""
                  style="min-width: 350px;"
                >{{ pickerDates.startDate | date }} - {{ pickerDates.endDate | date }}</template>
              </date-range-picker>
              <div class="float-right ml-3 d-md-inline-block">
                <!-- <b-form-select v-model="currentSelectedFilter" style="display: inline-block;color: #252B3A;border-color: #252B3A;" size="sm" :options="filterOptions"></b-form-select>&nbsp; -->
                  <div @click="openFilter()" ><FilterBtn :isActive="isFilterApplied" /></div>
                  <div v-if="showFilterSection" class="filter-section">
                    <div class="bottom-section float-right">
                        <div @click="openFilter()" class="mb-2"><FilterBtn :isActive="isFilterApplied" /></div>
                    </div>
                    <div class="my-3 h-50" style="margin-left: 4%; border-bottom: 1px solid #F0F0F0; padding-bottom: 20px;">
                        <button @click="saveFilter()" class="btn btn-primary btn-sm">
                          Apply
                        </button>
                        <button @click="clearFilter()" style="padding-left: 20px;" class="mr-2 btn btn-sm">Clear Filter</button>
                    </div>
                    <div class="top-section">
                        <div class="filter_channel">
                          <div class="filter_heading" style="padding-left: 20px;">COUNTRY</div>
                            <div class="checkbox-group" style="padding-left: 20px;">  
                              <input type="checkbox" id="filter_vietnam" value="VND" v-model="filter.currency">
                              <label for="filter_vietnam">Vietnam (VND)</label>
                            </div>
                            <div class="checkbox-group" style="padding-left: 20px;">  
                              <input type="checkbox" id="filter_thailand" value="THB" v-model="filter.currency">
                              <label for="filter_thailand">Thailand (THB)</label>
                            </div>
                            <!-- <div class="checkbox-group" style="padding-left: 20px;"> 
                              <input type="checkbox" id="filter_singapore" value="SGD" v-model="filter.currency">
                              <label for="filter_singapore">Singapore (SGD)</label>
                            </div> -->
                            <div class="checkbox-group" style="padding-left: 20px;"> 
                              <input type="checkbox" id="filter_indonesia" value="IDR" v-model="filter.currency">
                              <label for="filter_indonesia">Indonesia (IDR)</label>
                            </div>
                            <!-- <div class="checkbox-group" style="padding-left: 20px;"> 
                              <input type="checkbox" id="filter_malaysia" value="MYR" v-model="filter.currency">
                              <label for="filter_malaysia">Malaysia (MYR)</label>
                            </div>
                            <div class="checkbox-group" style="padding-left: 20px;"> 
                              <input type="checkbox" id="filter_philippines" value="PHP" v-model="filter.currency">
                              <label for="filter_philippines">Philippines (PHP)</label>
                            </div>
                            <div class="checkbox-group" style="padding-left: 20px;"> 
                              <input type="checkbox" id="filter_taiwan" value="TWD" v-model="filter.currency">
                              <label for="filter_taiwan">Taiwan (TWD)</label>
                            </div> -->
                        </div>
                        <div class="filter_method"> 
                            <div class="filter_heading" style="padding-left: 20px;">PAYMENT METHODS</div>
                            <div class="checkbox-group" style="padding-left: 20px;">  
                              <input type="checkbox" id="filter_wallet" value="WALLET" v-model="filter.method">
                              <label for="filter_wallet">Wallet</label>
                            </div>
                            <div class="checkbox-group" style="padding-left: 20px;">  
                              <input type="checkbox" id="filter_CARD" value="CARD" v-model="filter.method">
                              <label for="filter_CARD">Card</label>
                            </div>
                            <div class="checkbox-group" style="padding-left: 20px;"> 
                              <input type="checkbox" id="filter_INSTALLMENT" value="INSTALLMENT" v-model="filter.method">
                              <label for="filter_INSTALLMENT">INSTALLMENT</label>
                            </div>
                            <div class="checkbox-group" style="padding-left: 20px;"> 
                              <input type="checkbox" id="filter_BANK_TRANSFER" value="BANK_TRANSFER" v-model="filter.method">
                              <label for="filter_BANK_TRANSFER">Bank Transfer</label>
                            </div>
                            <div class="checkbox-group" style="padding-left: 20px;"> 
                              <input type="checkbox" id="filter_BNPL" value="BNPL" v-model="filter.method">
                              <label for="filter_BNPL">BNPL</label>
                            </div>
                            <div class="checkbox-group" style="padding-left: 20px;"> 
                              <input type="checkbox" id="filter_NET_BANKING" value="NET_BANKING" v-model="filter.method">
                              <label for="filter_NET_BANKING">Net Banking</label>
                            </div>
                            <div class="checkbox-group" style="padding-left: 20px;"> 
                              <input type="checkbox" id="filter_QR_CODE" value="QR_CODE" v-model="filter.method">
                              <label for="filter_QR_CODE">QR Code</label>
                            </div>
                        </div>
                        <div class="filter_channels"> 
                            <div class="filter_heading" style="padding-left: 20px;">PAYMENT CHANNELS</div>
                            <div class="checkbox-group" style="padding-left: 20px;">  
                              <input type="checkbox" id="filter_ESPAY" value="ESPAY" v-model="filter.channel">
                              <label for="filter_ESPAY">ESPAY</label>
                            </div>
                            <div class="checkbox-group" style="padding-left: 20px;">  
                              <input type="checkbox" id="filter_BAOKIM" value="BAOKIM" v-model="filter.channel">
                              <label for="filter_BAOKIM">BAOKIM</label>
                            </div>
                            <div class="checkbox-group" style="padding-left: 20px;"> 
                              <input type="checkbox" id="filter_9PAY" value="9PAY" v-model="filter.channel">
                              <label for="filter_9PAY">9PAY</label>
                            </div>
                            <div class="checkbox-group" style="padding-left: 20px;"> 
                              <input type="checkbox" id="filter_MOMOPAY" value="MOMOPAY" v-model="filter.channel">
                              <label for="filter_MOMOPAY">MOMOPAY</label>
                            </div>
                            <div class="checkbox-group" style="padding-left: 20px;"> 
                              <input type="checkbox" id="filter_GBPRIMEPAY" value="GBPRIMEPAY" v-model="filter.channel">
                              <label for="filter_GBPRIMEPAY">GBPRIMEPAY</label>
                            </div>
                        </div>
                    </div>
                  </div>
              </div>
            </div>
        </div>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-xl-12">
        <Stat />
      </div>
    </div>
    <div class="row">
      <div class="col-xl-8">
        <TransactionVolume :filter="appliedFilter" />
      </div>
      <div class="col-xl-4">
        <TransactionSeasonality :filter="appliedFilter" />
      </div>
    </div>
    <div class="row">
      <div class="col-xl-4">
        <TransactionByStatus />
      </div>
      <div class="col-xl-4">
        <TransactionByPG />
      </div>
      <div class="col-xl-4">
        <TransactionByType />
      </div>
    </div> -->

    <div class="row">
      <div class="col-xl-12">
        <Stat :filter="appliedFilter" />
      </div>
      <div class="col-xl-8">
        <div class="row">
        </div>
        <div class="row">
          <div class="col-xl-12">
            <TransactionVolumeStackedbar :filter="appliedFilter" :currentSelectedFilter ="currentSelectedFilter" />
          </div>
        </div>
        <div class="row">
          <div class="col-xl-12">
            <TransactionVolumeStackedbarByChannel :filter="appliedFilter" :currentSelectedFilter ="currentSelectedFilter" />
          </div>
        </div>
         <div class="row">
          <div class="col-xl-12">
            <TransactionVolumeStackedbarByMethod :filter="appliedFilter" :currentSelectedFilter ="currentSelectedFilter" />
          </div>
        </div>
      </div>
      <div class="col-xl-4">
        <div class="row">
          <div class="col-xl-12">
            <TransactionByStatus :filter="appliedFilter" />
          </div>
        </div>
        <div class="row">
          <div class="col-xl-12">
            <TransactionByPG :filter="appliedFilter" />
          </div>
        </div>
        <div class="row">
          <div class="col-xl-12">
            <TransactionByType :filter="appliedFilter" />
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style scoped>
  .filter-section{
      box-shadow: 0px 3px 10px #0000001a;
      background: #fff;
      position: absolute;
      top: 0px;
      z-index: 1;
      width: 380px;
      right:0px;
  }
  .top-section{
      border-bottom: 1px solid #F0F0F0;
      padding: 15px;
      padding-left: 5px;
      padding-top: 0px;
  }
  .clear_filter{
    color: #BFBFBF;
    display: inline-block;
    margin-left: 15px;
    font-size: 13px;
    cursor: pointer;
  }
  .bottom-section{
      padding: 15px;
      padding-left: 35px;
  }
  .filter_heading{
      color: #BFBFBF;
      padding-bottom: 15px;
      font-size: 13px;
      padding-top:10px ;
  }
  .checkbox-group{
    position: relative;
    display: inline-block;
  }
  .checkbox-group input{
    display: none;
  }
  .checkbox-group  label {
    cursor: pointer;
    font-size: 13px;
  }
  .checkbox-group  label:before {
    content:'';
    -webkit-appearance: none;
    background-color: #fff;
    border: 1px solid #DAD9D9;
    border-radius: 4px;
    padding: 7px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 10px;
    bottom:1px;
  }
  .checkbox-group input:checked+label:before {
    background-color: #252B3A;
    border: 1px solid #252B3A;
  }

  .checkbox-group input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    left: 25px;
    width: 5px;
    height: 9px;
    border: 1px solid #fff;
    border-width: 0px 2px 2px 0px;
    transform: rotate(45deg);
  }
  .filter_channel,.filter_method{
    display: block;
    border-bottom: 1px solid #F0F0F0;
  }
</style>
