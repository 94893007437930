<script>
import {getCurrency} from '../../../../helpers/utils';
import * as moment from "moment";
/**
 * Widget Component
 */
export default {
  props: ['filter'],
  computed: {
    statData() {
      return this.$store.state.remittancesDashboard.txn_stats;
    },
    appliedFilter() {
      return this.filter;
    }
  },
  methods: {
    getCurrencySymbol(currency) {
      return getCurrency(currency)
    },
    valIsFinite(value) {
      return value < 10000000000000000000000000;
    },
    getFormattedPeriod() {
      if(this.statData && this.statData.date_range) {
        const startDate = this.statData.date_range.start;
        const endDate = this.statData.date_range.end;
        if(this.filter == 'hours') {
          return `${moment(startDate).format('YYYY/MM/DD HH:mm')} - ${moment(endDate).format('HH:mm')}`;
        } else if(this.filter == 'days') {
          return `${moment(startDate).format('YYYY/MM/DD HH:mm')} - ${moment(endDate).format('HH:mm')}`;
        } else if(this.filter == 'weeks') {
          return `${moment(startDate).format('YYYY/MM/DD')} - ${moment(endDate).format('YYYY/MM/DD')}, Week: ${moment(startDate).week()}`;
        } else if(this.filter == 'months') {
          return `${moment(startDate).format('YYYY/MM')}`;
        } else if(this.filter == 'years') {
          return `${moment(startDate).format('YYYY')}`;
        } else {
          return `${moment(startDate).format('YYYY/MM/DD HH:mm')} - ${moment(endDate).format('YYYY/MM/DD HH:mm')}`;
        }
      }
    }
  },
};
</script>

<template>
  <div class="row">
    <!--  Net Transaction Volume -->
    <div class="col-md-4" v-if="statData.net_txns_volume || statData.net_txns_volume == 0">
      <div class="card">
        <div class="card-body" style="padding: 15px 10px; position: relative;">
          <div class="media" id="net_txns_volume_widget">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2" style="color: #252B3A;">{{ $t('views.dashboard.widget_net_transaction_volume') }} <br> {{ $t('views.dashboard.widget_net_transaction_volume2') }}</p>
              <div style="width: 35px;border: 2px solid #354052;" class="mb-2"></div>
              <h3 class="mb-0">{{ (statData.net_txns_volume).toLocaleString('en-US', {style : 'currency', currency : 'USD'}) }}</h3>
            </div>
            <!-- <div class="text-primary">
              <i :class="`ri-stack-line font-size-24`"></i>
            </div> -->
            <img src="@/assets/images/succesful_txn_amount.svg" style="position: absolute;right: 10px;" alt="" />
          </div>
          <!-- <b-tooltip target="net_txns_volume_widget" triggers="hover">
            <span v-if="filter === 'hours'" class="">{{ `Calculated hourly: ${getFormattedPeriod()}` }}</span>
            <span v-else-if="filter === 'days'" class="">{{ `Calculated daily: ${getFormattedPeriod()}` }}</span>
            <span v-else-if="filter === 'weeks'" class="">{{ `Calculated weekly: ${getFormattedPeriod()}` }}</span>
            <span v-else-if="filter === 'months'" class="">{{ `Calculated monthly: ${getFormattedPeriod()}` }}</span>
            <span v-else-if="filter === 'years'" class="">{{ `Calculated yearly: ${getFormattedPeriod()}` }}</span>
          </b-tooltip> -->
          <!-- <div class="text-truncate pt-2" v-if="valIsFinite(statData.net_txns_volume.percent_change)">
            <span style="font-size: 12px;" class="text-muted">{{ $t('views.dashboard.widget_from_previous_period') }}</span>
            <span v-if="statData.net_txns_volume.percent_change >= 0" style="color: #1cbb8c;" class="font-size-11 ml-2">
              <img class="align-middle" src="@/assets/images/dashboard_up.svg" alt="" />
              (<i class="ri-add-line align-middle"></i>{{ (statData.net_txns_volume.percent_change).toFixed(2) + '%' }})
            </span>
            <span v-else style="color: #ff3d60;" class="font-size-11 ml-2">
              <img class="align-middle" src="@/assets/images/dashboard_down.svg" alt="" />
              (<i class="ri-subtract-line align-middle"></i>{{ (-1 * statData.net_txns_volume.percent_change).toFixed(2) + '%' }})
            </span>
          </div>
          <div class="text-truncate pt-2" v-else>
            <span style="font-size: 12px;" class="text-muted">{{ $t('views.dashboard.widget_from_previous_period') }}</span>
            <span class="badge badge-soft-info font-size-11 ml-2">
              {{ 'NA'}}
            </span>
          </div> -->
        </div>
      </div>
    </div>

    <!--  Net Transaction Count -->
    <div class="col-md-4" v-if="statData.net_txns_count || statData.net_txns_count == 0">
      <div class="card">
        <div class="card-body" style="padding: 15px 10px; position: relative;">
          <div class="media" id="net_txns_count_widget">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2" style="color: #252B3A;">{{ $t('views.dashboard.widget_net_transaction_volume3') }} <br> {{ $t('views.dashboard.widget_net_transaction_count') }}</p>
              <div style="width: 35px;border: 2px solid #354052;" class="mb-2"></div>
              <h3 class="mb-0">{{ statData.net_txns_count }}</h3>
            </div>
            <!-- <div class="text-primary">
              <i :class="`ri-stack-line font-size-24`"></i>
            </div> -->
            <img src="@/assets/images/succesful_txn_count.svg" style="position: absolute;right: 10px;" alt="" />
          </div>
          <!-- <b-tooltip target="net_txns_count_widget" triggers="hover">
            <span v-if="filter === 'hours'" class="">{{ `Calculated hourly: ${getFormattedPeriod()}` }}</span>
            <span v-else-if="filter === 'days'" class="">{{ `Calculated daily: ${getFormattedPeriod()}` }}</span>
            <span v-else-if="filter === 'weeks'" class="">{{ `Calculated weekly: ${getFormattedPeriod()}` }}</span>
            <span v-else-if="filter === 'months'" class="">{{ `Calculated monthly: ${getFormattedPeriod()}` }}</span>
            <span v-else-if="filter === 'years'" class="">{{ `Calculated yearly: ${getFormattedPeriod()}` }}</span>
          </b-tooltip>
          <div class="text-truncate pt-2" v-if="valIsFinite(statData.net_txns_count.percent_change)">
            <span style="font-size: 12px;" class="text-muted">{{ $t('views.dashboard.widget_from_previous_period') }}</span>
            <span v-if="statData.net_txns_count.percent_change >= 0" style="color: #1cbb8c;" class="font-size-11 ml-2">
              <img class="align-middle" src="@/assets/images/dashboard_up.svg" alt="" />
              (<i class="ri-add-line align-middle"></i>{{ (statData.net_txns_count.percent_change).toFixed(2) + '%' }})
            </span>
            <span v-else style="color: #ff3d60;" class="font-size-11 ml-2">
              <img class="align-middle" src="@/assets/images/dashboard_down.svg" alt="" />
              (<i class="ri-subtract-line align-middle"></i>{{ (-1 * statData.net_txns_count.percent_change).toFixed(2) + '%' }})
            </span>
          </div>
          <div class="text-truncate pt-2" v-else>
            <span style="font-size: 12px;" class="text-muted">{{ $t('views.dashboard.widget_from_previous_period') }}</span>
            <span class="badge badge-soft-info font-size-11 ml-2">
              {{ 'NA'}}
            </span>
          </div> -->
        </div>
      </div>
    </div>

    <!--  Average Transaction Value -->
    <div class="col-md-4" v-if="statData.average_txns_volume || statData.average_txns_volume == 0">
      <div class="card">
        <div class="card-body" style="padding: 15px 10px; position: relative;">
          <div class="media" id="average_txns_volume_widget">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2" style="color: #252B3A;">{{ $t('views.dashboard.widget_average_order_value') }} <br> {{ $t('views.dashboard.widget_average_order_value2') }}</p>
              <div style="width: 35px;border: 2px solid #354052;" class="mb-2"></div>
              <h3 class="mb-0">{{ (statData.average_txns_volume).toLocaleString('en-US', {style : 'currency', currency : 'USD'}) }}</h3>
            </div>
            <!-- <div class="text-primary">
              <i :class="`ri-stack-line font-size-24`"></i>
            </div> -->
            <img src="@/assets/images/dashboard_avrg_value.svg" style="position: absolute;right: 10px; filter: grayscale(100%);" alt="" />
          </div>
          <!-- <b-tooltip target="average_txns_volume_widget" triggers="hover">
            <span v-if="filter === 'hours'" class="">{{ `Calculated hourly: ${getFormattedPeriod()}` }}</span>
            <span v-else-if="filter === 'days'" class="">{{ `Calculated daily: ${getFormattedPeriod()}` }}</span>
            <span v-else-if="filter === 'weeks'" class="">{{ `Calculated weekly: ${getFormattedPeriod()}` }}</span>
            <span v-else-if="filter === 'months'" class="">{{ `Calculated monthly: ${getFormattedPeriod()}` }}</span>
            <span v-else-if="filter === 'years'" class="">{{ `Calculated yearly: ${getFormattedPeriod()}` }}</span>
          </b-tooltip>
          <div class="text-truncate pt-2" v-if="valIsFinite(statData.average_txns_volume.percent_change)">
            <span style="font-size: 12px;" class="text-muted">{{ $t('views.dashboard.widget_from_previous_period') }}</span>
            <span v-if="statData.average_txns_volume.percent_change >= 0" style="color: #1cbb8c;" class="font-size-11 ml-2">
              <img class="align-middle" src="@/assets/images/dashboard_up.svg" alt="" />
              (<i class="ri-add-line align-middle"></i>{{(statData.average_txns_volume.percent_change).toFixed(2) + '%'}})
            </span>
            <span v-else style="color: #ff3d60;" class="font-size-11 ml-2">
               <img class="align-middle" src="@/assets/images/dashboard_down.svg" alt="" />
              (<i class="ri-subtract-line align-middle"></i>{{ (-1 * statData.average_txns_volume.percent_change).toFixed(2) + '%'}})
            </span>
          </div>
          <div class="text-truncate pt-2" v-else>
            <span style="font-size: 12px;" class="text-muted">{{ $t('views.dashboard.widget_from_previous_period') }}</span>
            <span class="badge badge-soft-info font-size-11 ml-2">
              {{ 'NA'}}
            </span>
          </div> -->
        </div>
      </div>
    </div>
        <!--  Average Transaction Value -->
    <div class="col-md-4" v-if="statData.fully_settled_volume || statData.fully_settled_volume == 0">
      <div class="card">
        <div class="card-body" style="padding: 15px 10px; position: relative;">
          <div class="media" id="average_txns_volume_widget">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2" style="color: #252B3A;">Fully <br> Settled</p>
              <div style="width: 35px;border: 2px solid #354052;" class="mb-2"></div>
              <h3 class="mb-0">{{ (statData.fully_settled_volume).toLocaleString('en-US', {style : 'currency', currency : 'USD'}) }}</h3>
            </div>
            <!-- <div class="text-primary">
              <i :class="`ri-stack-line font-size-24`"></i>
            </div> -->
            <img src="@/assets/images/fully_settled.svg" style="position: absolute;right: 10px;" alt="" />
          </div>
          <!-- <b-tooltip target="average_txns_volume_widget" triggers="hover">
            <span v-if="filter === 'hours'" class="">{{ `Calculated hourly: ${getFormattedPeriod()}` }}</span>
            <span v-else-if="filter === 'days'" class="">{{ `Calculated daily: ${getFormattedPeriod()}` }}</span>
            <span v-else-if="filter === 'weeks'" class="">{{ `Calculated weekly: ${getFormattedPeriod()}` }}</span>
            <span v-else-if="filter === 'months'" class="">{{ `Calculated monthly: ${getFormattedPeriod()}` }}</span>
            <span v-else-if="filter === 'years'" class="">{{ `Calculated yearly: ${getFormattedPeriod()}` }}</span>
          </b-tooltip>
          <div class="text-truncate pt-2" v-if="valIsFinite(statData.average_txns_volume.percent_change)">
            <span style="font-size: 12px;" class="text-muted">{{ $t('views.dashboard.widget_from_previous_period') }}</span>
            <span v-if="statData.average_txns_volume.percent_change >= 0" style="color: #1cbb8c;" class="font-size-11 ml-2">
              <img class="align-middle" src="@/assets/images/dashboard_up.svg" alt="" />
              (<i class="ri-add-line align-middle"></i>{{(statData.average_txns_volume.percent_change).toFixed(2) + '%'}})
            </span>
            <span v-else style="color: #ff3d60;" class="font-size-11 ml-2">
               <img class="align-middle" src="@/assets/images/dashboard_down.svg" alt="" />
              (<i class="ri-subtract-line align-middle"></i>{{ (-1 * statData.average_txns_volume.percent_change).toFixed(2) + '%'}})
            </span>
          </div>
          <div class="text-truncate pt-2" v-else>
            <span style="font-size: 12px;" class="text-muted">{{ $t('views.dashboard.widget_from_previous_period') }}</span>
            <span class="badge badge-soft-info font-size-11 ml-2">
              {{ 'NA'}}
            </span>
          </div> -->
        </div>
      </div>
    </div>
        <!--  Average Transaction Value -->
    <div class="col-md-4" v-if="statData.psp_settled_volume || statData.psp_settled_volume == 0">
      <div class="card">
        <div class="card-body" style="padding: 15px 10px; position: relative;">
          <div class="media" id="average_txns_volume_widget">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2" style="color: #252B3A;">PSP <br> Settled</p>
              <div style="width: 35px;border: 2px solid #354052;" class="mb-2"></div>
              <h3 class="mb-0">{{ (statData.psp_settled_volume).toLocaleString('en-US', {style : 'currency', currency : 'USD'}) }}</h3>
            </div>
            <!-- <div class="text-primary">
              <i :class="`ri-stack-line font-size-24`"></i>
            </div> -->
            <img src="@/assets/images/PSP_settled.svg" style="position: absolute;right: 10px;" alt="" />
          </div>
          <!-- <b-tooltip target="average_txns_volume_widget" triggers="hover">
            <span v-if="filter === 'hours'" class="">{{ `Calculated hourly: ${getFormattedPeriod()}` }}</span>
            <span v-else-if="filter === 'days'" class="">{{ `Calculated daily: ${getFormattedPeriod()}` }}</span>
            <span v-else-if="filter === 'weeks'" class="">{{ `Calculated weekly: ${getFormattedPeriod()}` }}</span>
            <span v-else-if="filter === 'months'" class="">{{ `Calculated monthly: ${getFormattedPeriod()}` }}</span>
            <span v-else-if="filter === 'years'" class="">{{ `Calculated yearly: ${getFormattedPeriod()}` }}</span>
          </b-tooltip>
          <div class="text-truncate pt-2" v-if="valIsFinite(statData.average_txns_volume.percent_change)">
            <span style="font-size: 12px;" class="text-muted">{{ $t('views.dashboard.widget_from_previous_period') }}</span>
            <span v-if="statData.average_txns_volume.percent_change >= 0" style="color: #1cbb8c;" class="font-size-11 ml-2">
              <img class="align-middle" src="@/assets/images/dashboard_up.svg" alt="" />
              (<i class="ri-add-line align-middle"></i>{{(statData.average_txns_volume.percent_change).toFixed(2) + '%'}})
            </span>
            <span v-else style="color: #ff3d60;" class="font-size-11 ml-2">
               <img class="align-middle" src="@/assets/images/dashboard_down.svg" alt="" />
              (<i class="ri-subtract-line align-middle"></i>{{ (-1 * statData.average_txns_volume.percent_change).toFixed(2) + '%'}})
            </span>
          </div>
          <div class="text-truncate pt-2" v-else>
            <span style="font-size: 12px;" class="text-muted">{{ $t('views.dashboard.widget_from_previous_period') }}</span>
            <span class="badge badge-soft-info font-size-11 ml-2">
              {{ 'NA'}}
            </span>
          </div> -->
        </div>
      </div>
    </div>
        <!--  Average Transaction Value -->
    <div class="col-md-4" v-if="statData.unsettled_volume || statData.unsettled_volume == 0">
      <div class="card">
        <div class="card-body" style="padding: 15px 10px; position: relative;">
          <div class="media" id="average_txns_volume_widget">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2" style="color: #252B3A;">Unsettled <br>  (USD)</p>
              <div style="width: 35px;border: 2px solid #354052;" class="mb-2"></div>
              <h3 class="mb-0">{{ (statData.unsettled_volume).toLocaleString('en-US', {style : 'currency', currency : 'USD'}) }}</h3>
            </div>
            <!-- <div class="text-primary">
              <i :class="`ri-stack-line font-size-24`"></i>
            </div> -->
            <img src="@/assets/images/unsettled.svg" style="position: absolute;right: 10px;" alt="" />
          </div>
          <!-- <b-tooltip target="average_txns_volume_widget" triggers="hover">
            <span v-if="filter === 'hours'" class="">{{ `Calculated hourly: ${getFormattedPeriod()}` }}</span>
            <span v-else-if="filter === 'days'" class="">{{ `Calculated daily: ${getFormattedPeriod()}` }}</span>
            <span v-else-if="filter === 'weeks'" class="">{{ `Calculated weekly: ${getFormattedPeriod()}` }}</span>
            <span v-else-if="filter === 'months'" class="">{{ `Calculated monthly: ${getFormattedPeriod()}` }}</span>
            <span v-else-if="filter === 'years'" class="">{{ `Calculated yearly: ${getFormattedPeriod()}` }}</span>
          </b-tooltip>
          <div class="text-truncate pt-2" v-if="valIsFinite(statData.average_txns_volume.percent_change)">
            <span style="font-size: 12px;" class="text-muted">{{ $t('views.dashboard.widget_from_previous_period') }}</span>
            <span v-if="statData.average_txns_volume.percent_change >= 0" style="color: #1cbb8c;" class="font-size-11 ml-2">
              <img class="align-middle" src="@/assets/images/dashboard_up.svg" alt="" />
              (<i class="ri-add-line align-middle"></i>{{(statData.average_txns_volume.percent_change).toFixed(2) + '%'}})
            </span>
            <span v-else style="color: #ff3d60;" class="font-size-11 ml-2">
               <img class="align-middle" src="@/assets/images/dashboard_down.svg" alt="" />
              (<i class="ri-subtract-line align-middle"></i>{{ (-1 * statData.average_txns_volume.percent_change).toFixed(2) + '%'}})
            </span>
          </div>
          <div class="text-truncate pt-2" v-else>
            <span style="font-size: 12px;" class="text-muted">{{ $t('views.dashboard.widget_from_previous_period') }}</span>
            <span class="badge badge-soft-info font-size-11 ml-2">
              {{ 'NA'}}
            </span>
          </div> -->
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->
</template>
